import { Box, Button, Flex } from '@mantine/core';
import { couponStyle, logoContainerStyle, cutoffContainerStyle, couponBodyStyle, logoStyle, brandStyle, priceStyle, validUntilStyle, valueStyle, titleStyle } from './styles.css';
import { theme } from '~/shared/theme';
import { Coupon } from '~/shared/types/Coupon';
import CheckIcon from '~/assets/icons/Check.svg';
import { useState } from 'react';
import CouponPopup from '../CouponPopup';

type Props = {
    coupon: Coupon;
    handleClick: (coupon: Coupon) => void;
    isDisabled?: boolean;
};

type DialogType = 'code' | 'details' | null;

const CouponCard = ({ coupon, handleClick, isDisabled = false }: Props) => {
    const [dialogType, setDialogType] = useState<DialogType>(null);
    const validity = new Date(coupon.validity);
    const formattedDate = validity.getTime() ? validity.toLocaleDateString('en', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    }) : '';
    const logo = import.meta.env.VITE_API_URL + coupon.logo;

    return (
        <div className={couponStyle} data-disabled={!!coupon.is_used} onClick={() => setDialogType('details')}>
            <div className={logoContainerStyle}>
                <img src={logo} alt="Logo" className={logoStyle} />
                <span className={brandStyle}>{coupon.brand} {coupon.is_used}</span>
            </div>
            <div className={cutoffContainerStyle} data-disabled={!!coupon.is_used}>
                <Flex className={couponBodyStyle}>
                    <Box fw='bold' className={titleStyle} title={coupon.title}>{coupon.title}</Box>
                    <Box fw='normal' className={valueStyle} title={coupon.value}>{coupon.value}</Box>
                    {!coupon.is_used ? (
                        <Button
                            fw='normal'
                            m='1em 0 0.2em 0'
                            color={theme.colors.orange[5]}
                            variant='filled'
                            radius="xl"
                            className={priceStyle}
                            onClick={e => { e.preventDefault(); e.stopPropagation(); handleClick(coupon); }}
                            disabled={isDisabled}
                        >
                            Buy for {coupon.price} PONs
                        </Button>
                    ) : (
                        <Button
                            fw='normal'
                            m='1em 0 0.2em 0'
                            color={theme.colors.orange[5]}
                            variant='filled'
                            radius="xl"
                            className={priceStyle}
                            onClick={e => { e.preventDefault(); e.stopPropagation(); setDialogType('code'); }}
                        >
                            Show Code
                        </Button>
                    )}
                    <Box className={validUntilStyle}>{formattedDate ? `Valid until ${formattedDate}` : ''}</Box>
                </Flex>
                <Box style={{ marginLeft: 'auto', marginRight: '1rem', color: '#FF8A00' }}>
                    {coupon.is_used ? <CheckIcon /> : null}
                </Box>
            </div>

            <CouponPopup
                dialogType={dialogType}
                coupon={coupon}
                onClose={() => setDialogType(null)}
                onBuy={handleClick}
                isDisabled={isDisabled}
            />
        </div>
    );
}

export default CouponCard;
