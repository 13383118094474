import { headers } from "./headers";
import type { TaskState } from "../types/Task";

type Data = {
    id: string;
    user_id: number;
    is_daily: boolean;
    reward: number;
};

const submitTask = async (
    data: Data,
    onSuccess?: (task: Pick<TaskState, 'completion_ts'>) => void,
    onError?: (e: any) => void
) => {
    try {
        const response = await fetch(`${import.meta.env.VITE_API_URL}/task`, {
            headers,
            method: 'POST',
            body: JSON.stringify(data)
        });
        const res = await response.json();
        if (response.ok) {
            onSuccess?.(res);
        } else {
            onError?.(res);
        }
    } catch (e) {
        console.error(e);
        onError?.(e);
    }
};

export default submitTask;
