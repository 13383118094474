import type { User } from "../types/User";
import { headers } from "./headers";

type Data = {
    id: number;
    balance: number;
}

const tap = async (
    data: Data,
    onSuccess?: (user: User) => void,
    onError?: (e: any) => void
) => {
    try {
        const response = await fetch(`${import.meta.env.VITE_API_URL}/tap`, {
            headers,
            method: 'POST',
            body: JSON.stringify(data)
        });

        const res = await response.json();
        onSuccess?.(res);
    } catch (e) {
        console.error(e);
        onError?.(e);
    }
};

export default tap;
