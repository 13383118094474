import { createTheme } from '@mantine/core';
import { themeToVars } from '@mantine/vanilla-extract';
import { sfProDisplay, sfProText } from './fontFaces.css';

export const mantineTheme = createTheme({
  colors: {
    grey: ['#000000', '#212121', '#78797E', '', '', '', '', '', '', ''],
    accent: ['#FF8A00', '#FFB800', '#FF8C00', '#FFD600', '', '', '', '', '', '', ''],
    dark: ['#fff', '#c9c9c9', '#828282', '#696969', '#424242', '#2e2e2e', '#171717', '#000', '#000', '#000'],
  },
  primaryShade: 9,
  other: {
    gradients: {
      progress: `linear-gradient(#FFB800, rgba(255, 138, 0, 1))`,
      button: `linear-gradient(#FFB600, #FF8C00)`,
    }
  },
  primaryColor: 'accent',
  fontFamily: sfProText,
  headings: { fontFamily: sfProDisplay },
});

export const theme = themeToVars(mantineTheme);
