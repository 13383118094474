import { SDKProvider, useLaunchParams } from "@tma.js/sdk-react";
import { AppRouter } from "./config/router";
import { useEffect } from "react";

import "../shared/fontFaces.css"
import { mantineTheme } from "../shared/theme";
import { MantineProvider } from "@mantine/core";
import '@mantine/core/styles.css';
import { ProvideGameContext } from "~/shared/UserContext";

export function App() {
    let debug = false;
    try {
        const lp = useLaunchParams();
        debug = lp.startParam === "debug";
    } catch (err) {
        // do nothing
    }

    useEffect(() => {
        if (debug) {
          import("eruda").then((lib) => lib.default.init());
        }
    }, [debug]);

    return (
        <MantineProvider theme={mantineTheme} defaultColorScheme="dark">
        <SDKProvider acceptCustomStyles debug={debug}>
            <ProvideGameContext>
                <AppRouter />
            </ProvideGameContext>
        </SDKProvider>
        </MantineProvider>
    )
}