import type { User } from '../types/User';
import createUser from './createUser';
import { headers } from './headers';

const getBalance = async (
	id: number,
	onSuccess?: (user: User) => void,
	onError?: (e: any) => void
) => {
	try {
		createUser({
			id,
			name: '',
		});
		await fetch(`${import.meta.env.VITE_API_URL}/user/${id}`, {
			headers,
		});
		const response = await fetch(`${import.meta.env.VITE_API_URL}/balances/${id}`, {
			headers,
		});
		const res = await response.json();

		if (response.ok) {
			onSuccess?.(res);
		} else {
			onError?.(res);
		}
	} catch (e) {
		console.error(e);
		onError?.(e);
	}
};

export default getBalance;
