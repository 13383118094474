import { PropsWithChildren } from "react";
import * as styles from './styles.css';

import ClickIcon from '~/assets/icons/Shine.svg';
import { Box, Flex, Title } from "@mantine/core";

interface Props extends PropsWithChildren {
  headline: string;
  smallerHeadline: string;
  subheadline: string;
  childBoxStyle?: any;
}

export const Page = ({ headline, smallerHeadline, subheadline, childBoxStyle, children }: Props) => (
  <>
    <Flex display='flex' justify='center' align='center' direction='column' w='100vw'>
      <Title order={1} m='0.5em 0 0 0' className={styles.titleStyle}>
        <Flex display='flex' w='100vw' direction='row' justify='center' align='center' m='0 0 0 0'>
          <ClickIcon />
          <div>
            {headline} <small>{smallerHeadline}</small>
          </div>
        </Flex>
      </Title>
      <Title order={3} m='0 0 0 0' fw='normal'>
        {subheadline}
      </Title>
    </Flex>
    <Box style={childBoxStyle}>
      {children}
    </Box>
  </>
);
