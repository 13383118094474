import { useEffect, useMemo } from "react";
import { Router, Routes } from "react-router-dom";
import { AppRoot } from "@telegram-apps/telegram-ui";
import {
  bindMiniAppCSSVars,
  bindThemeParamsCSSVars,
  bindViewportCSSVars,
  initNavigator,
  useLaunchParams,
  useMiniApp,
  useThemeParams,
  useViewport,
} from "@tma.js/sdk-react";
import { useIntegration } from "@tma.js/react-router-integration";

import { setupMockEnv } from "~/shared/mockEnv";
import { DefaultRoutesModule } from "~/domains/default/routes.module";

// setupMockEnv();

export const AppRouter = () => {
  let lp, miniApp, themeParams, viewport;
  try {
    lp = useLaunchParams();
    miniApp = useMiniApp();
    themeParams = useThemeParams();
    viewport = useViewport();
  } catch (err) {
    lp = {};
    miniApp = {};
    themeParams = {};
    viewport = {};
  }

  useEffect(() => {
    bindMiniAppCSSVars(miniApp, themeParams);
  }, [miniApp, themeParams]);

  useEffect(() => {
    bindThemeParamsCSSVars(themeParams);
  }, [themeParams]);

  useEffect(() => {
    if (viewport) {
      bindViewportCSSVars(viewport);
    }
  }, [viewport]);

  const navigator = useMemo(() => initNavigator("app-navigation-state"), []);
  const [location, reactNavigator] = useIntegration(navigator);

  // useEffect(() => {
  //   navigator.attach();
  //   return () => navigator.detach();
  // }, [navigator]);

  return (
    <AppRoot
      appearance={"dark"}
      platform={["macos", "ios"].includes(lp.platform) ? "ios" : "base"}
    >
      <Router location={location} navigator={reactNavigator}>
        <Routes>
          {DefaultRoutesModule()}
        </Routes>
      </Router>
    </AppRoot>
  );
};
