import { headers } from "./headers";
import type { User } from "../types/User";

type Data = {
    id: number;
    ref?: number;
    name: string;
};

const createUser = async (
    data: Data,
    onSuccess?: (user: User) => void,
    onError?: (e: any) => void
) => {
    try {
        const response = await fetch(`${import.meta.env.VITE_API_URL}/user`, {
            headers,
            method: 'POST',
            body: JSON.stringify(data)
        });
        const res = await response.json();

        if (response.ok) {
            onSuccess?.(res);
        } else {
            onError?.(res);
        }
    } catch (e) {
        console.error(e);
        onError?.(e);
    }
};

export default createUser;
