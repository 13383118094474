import { Page } from "~/shared/components/Page";
import { Tasks, Task } from "./components/Tasks";
import { Flex } from "@mantine/core";

import calendarIcon from '~/assets/img/tasks/calendar.png';
import userIcon from '~/assets/img/tasks/user.png';
import TelegramIcon from '~/assets/icons/telegram.svg';
import { useUserContext } from "~/shared/UserContext";
import { useEffect, useState } from "react";
import { TaskState } from "~/shared/types/Task";
import getTasks from "~/shared/api/getTasks";
import WebApp from "@twa-dev/sdk";
import getBalance from "~/shared/api/getBalance";
import submitTask from "~/shared/api/submitTask ";
import { Spinner } from "~/shared/components/Spinner";
import { ErrorNotice } from "~/shared/components/ErrorNotice";

const tasks = [
    { id: 'daily', title: "Daily Reward", icon: calendarIcon, reward: 500, completed: false },
    { id: 'invite', title: 'Invite friends and get coins', icon: userIcon, reward: 5000, completed: false },
    { id: 'channel', title: 'Subscribe our Telegram', icon: TelegramIcon, reward: 1000, completed: false },
    { id: 'group', title: 'Join ClickCouPON Telegram Group', icon: TelegramIcon, reward: 1000, completed: false }
];

export const RefPage = () => {
    const { user, setUser } = useUserContext();
    const [tasksState, setTasksState] = useState<TaskState[]>();
    const [status, setStatus] = useState('loading');
    const [error, setError] = useState('');

    useEffect(() => {
        if (user?.id) {
            getTasks(user.id,
                t => { setTasksState(t); setStatus('success'); },
                e => { setStatus('error'); setError(e.message); }
            );
        }
    }, [user?.id]);

    const handleClick = (task: Task) => {
        if (!task.completed && user) {
            const onSuccess = () => {
                WebApp.showPopup({
                    title: task.title,
                    message: `Successfully received ${task.reward} PONs`
                });
                setStatus('loading');
                getBalance(user.id, setUser);
                getTasks(user.id,
                    t => { setTasksState(t); setStatus('success'); },
                    e => { setStatus('error'); setError(e.message); }
                );
            };
            if (task.id === 'channel') {
                WebApp.openTelegramLink('https://t.me/Click_CouPON');
            } else if (task.id === 'group') {
                WebApp.openTelegramLink('https://t.me/click_coupon_group');
            } else if (task.id === 'invite') {
                WebApp.openTelegramLink(`https://t.me/share/url?url=https://t.me/${import.meta.env.VITE_BOT_USERNAME}?start=${user.id}`);
            }

            if (task.id !== 'invite') {
                submitTask({
                    id: task.id,
                    user_id: user.id,
                    is_daily: task.id === 'daily',
                    reward: task.reward ?? 0
                }, onSuccess);
            }
        }
    };

    const isTaskCompleted = (task: Task) => {
        const taskState = tasksState?.find(t => t.id === task.id);
        if (!taskState) {
            return false;
        }
        if (task.id === 'daily') {
            const now = new Date().getTime();
            const day = 1000 * 60 * 60 * 24;
            return now - taskState.completion_ts <= day;
        }
        return true;
    };

    return <>
        <Flex
            display='flex'
            direction='column'
            align='center'
            w='100dvw'
            h='calc(100dvh - 65px)'
            justify='space-between'
            style={{ overflowY: 'scroll', overflowX: 'hidden' }}
        >
            <Page
                headline={(user?.balance ?? 0).toString()}
                smallerHeadline="PONs"
                subheadline="Gold"
                childBoxStyle={{ width: '100%', marginBottom: 'auto', minHeight: '50%' }}
            >
                {status === 'loading' ? <Spinner /> : (
                    status === 'error' ? <ErrorNotice message={error} /> : (
                        <Tasks
                            tasks={tasks.map(task => ({
                                ...task,
                                completed: isTaskCompleted(task)
                            }))}
                            handleClick={handleClick}
                        />
                    )
                )}
            </Page>
        </Flex>
    </>;
};
