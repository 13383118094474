import { Box, Text } from "@mantine/core";
import { boxStyle, bigBoxStyle, taskIconStyle, taskIconContainerStyle } from "./styles.css";
import { Task } from "..";

type Props = {
    task: Task;
    handleClick: (task: Task) => void;
}

export const DailyTask = ({ task, handleClick }: Props) => {
    const Icon = task.icon;
    return (
        <Box
            p='.75rem'
            className={`${boxStyle} ${bigBoxStyle}`}
            data-disabled={task.completed}
            onClick={() => handleClick(task)}
            style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'nowrap',
                overflow: 'hidden'
            }}
        >
            <Box className={taskIconContainerStyle}>
                {typeof task.icon === 'object' ? <Icon /> :
                    <img src={task.icon} className={taskIconStyle} />
                }
            </Box>
            <Box style={{ flex: '1 1 auto', overflow: 'hidden' }}>
                <Text style={{ fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {task.title}
                </Text>
                <Text style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '0.8rem', color: 'grey' }}>
                    {task.reward} PONs
                </Text>
            </Box>
            <Box style={{ marginLeft: 'auto' }}>
                {task.completed ? <Text style={{ fontSize: '1.5rem', color: 'orange' }}>✔️</Text> : null}
            </Box>
        </Box>
    );
};
