import { Box, Progress } from "@mantine/core";
import { theme } from "~/shared/theme";
import { PropsWithChildren } from "react";
import { progressText } from "./styles.css";

interface Props extends PropsWithChildren {
    className: string;
    progress: number;
    currentProgress: number;
    maxValue: number;
}

export const ProgressBar = ({ className, progress, currentProgress, maxValue }: Props) => {
    return (
        <Box className={className}>
            <Box className={progressText}>
                ⚡{currentProgress}/{maxValue}
            </Box>
            <Progress 
                color={theme.colors.accent[1]} 
                value={progress} 
                style={{ height: '1em', borderRadius: '.5em', margin: "0 0 .5rem 0" }}
            />
        </Box>
    );
};
