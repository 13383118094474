import { useMemo, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import * as styles from './styles.css';

import clickIcon from '~/assets/icons/Shine.svg';
import couponIcon from '~/assets/icons/Coupon.svg';
import actionsIcon from '~/assets/icons/Actions.svg';

export const TabNavigation = () => {
  const navigate = useNavigate();

  const tabs = useMemo(() => [
    { id: 'click', text: "Click", path: '/', icon: clickIcon },
    { id: 'coupons', text: "Coupons", path: '/coupons', icon: couponIcon },
    { id: 'ref', text: "Ref", path: '/ref', icon: actionsIcon },
  ], []);

  const [currentTab, setCurrentTab] = useState(tabs[0].id);

  const handleTabClick = (id, path) => {
    setCurrentTab(id);
    navigate(path);
  };

  return (
    <div className={styles.pageContainer}>
      <div className={styles.contentContainer}>
        <Outlet />
      </div>
      <div className={styles.tabbarContainer}>
        {tabs.map(({ id, text, icon: Icon, path }) => (
          <div
            key={id}
            className={`${styles.tabItem} ${id === currentTab ? styles.selectedTabItem : ''}`}
            onClick={() => handleTabClick(id, path)}
          >
            {Icon && <Icon />}
            <span className={styles.tabName}>{text}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
