import { headers } from "./headers";
import type { TaskState } from "../types/Task";


const getTasks = async (
    user_id: number,
    onSuccess?: (tasks: TaskState[]) => void,
    onError?: (e: any) => void
) => {
    try {
        const response = await fetch(`${import.meta.env.VITE_API_URL}/tasks/${user_id}`, { headers });
        const res = await response.json();
        onSuccess?.(res);
    } catch (e) {
        console.error(e);
        onError?.(e);
    }
};

export default getTasks;
