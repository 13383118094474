import { Route } from "react-router-dom";
import { DefaultLayout } from "~/layouts/default.layout";
import { DefaultRoutes } from "~/shared/routes/default.routes";
import { CouponPage } from "./pages/coupon/coupon.page";
import { RefPage } from "./pages/ref/ref.page";
import { ClickPage } from "./pages/click/click.page";

export const DefaultRoutesModule = () => {
  return (
    <>
      <Route element={<DefaultLayout />}>
        <Route path={DefaultRoutes.Root} element={<ClickPage />} />
        <Route path={DefaultRoutes.Coupons} element={<CouponPage />} />
        <Route path={DefaultRoutes.Ref} element={<RefPage />} />
      </Route>
    </>
  );
};
