import { headers } from "./headers";

type Data = {
    coupon_id: number;
    user_id: number;
};

const buyCoupon = async (
    data: Data,
    onSuccess?: (v: Data) => void,
    onError?: (e: any) => void
) => {
    try {
        const response = await fetch(`${import.meta.env.VITE_API_URL}/coupon`, {
            headers,
            method: 'POST',
            body: JSON.stringify(data)
        });
        const res = await response.json();
        if (response.ok) {
            onSuccess?.(res);
        } else {
            onError?.(res);
        }
    } catch (e) {
        console.error(e);
        onError?.(e);
    }
};

export default buyCoupon;
